import { useEffect, useMemo } from 'react';
import { setup } from '@onemedical/command-palette';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLazyQuery } from '@apollo/client';
import { PatientAutocompleteResult, patientAutocompleteQuery } from './PatientSearchBox';

const dobFormatter = new Intl.DateTimeFormat('en-US');

const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;

function CommandPalette() {
  const { commandPaletteInitialExperiment } = useFlags();

  const [search, { data: results }] = useLazyQuery<
    { patientSearch: PatientAutocompleteResult[] },
    { textQuery: string }
  >(patientAutocompleteQuery);

  const addResultsToSearch = useMemo(() => {
    if (!commandPaletteInitialExperiment) {
      return () => {};
    }
    const { asyncActionBuilder } = setup({
      params: {
        adminUrl: '',
        onelifeUrl: process.env.REACT_APP_ONELIFE_URL!,
        templateUrl: process.env.REACT_APP_TEMPLATE_MANAGER_URL!,
      },
    });

    return asyncActionBuilder({
      action: {
        id: 'patients',
        title: 'Search for patients',
      },
      debounce: 200,
      async onSearch(query) {
        search({ variables: { textQuery: query } });
        return [];
      },
    });
  }, [commandPaletteInitialExperiment, search]);

  useEffect(() => {
    if (!results?.patientSearch) {
      return;
    }
    addResultsToSearch(
      results.patientSearch.map((result) => {
        const { dob, label, id } = result;

        const dobString = dob ? ` (${dobFormatter.format(new Date(dob))})` : '';

        return {
          id: result.id.toString(),
          title: `${label}${dobString}`,
          url: `${chartUrl}/#/patients/${id}/chart/summaries/new`,
        };
      }),
    );
  }, [results, addResultsToSearch]);

  return null;
}

export default CommandPalette;
